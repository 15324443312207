export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Byte: { input: any; output: any };
  Currency: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Duration: { input: any; output: any };
  EmailAddress: { input: any; output: any };
  GUID: { input: any; output: any };
  HSL: { input: any; output: any };
  HSLA: { input: any; output: any };
  HexColorCode: { input: any; output: any };
  Hexadecimal: { input: any; output: any };
  IBAN: { input: any; output: any };
  IPv4: { input: any; output: any };
  IPv6: { input: any; output: any };
  ISBN: { input: any; output: any };
  ISO8601Duration: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  JWT: { input: any; output: any };
  Latitude: { input: any; output: any };
  LocalDate: { input: any; output: any };
  LocalEndTime: { input: any; output: any };
  LocalTime: { input: any; output: any };
  Long: { input: any; output: any };
  Longitude: { input: any; output: any };
  MAC: { input: any; output: any };
  NegativeFloat: { input: any; output: any };
  NegativeInt: { input: any; output: any };
  NonEmptyString: { input: any; output: any };
  NonNegativeFloat: { input: any; output: any };
  NonNegativeInt: { input: any; output: any };
  NonPositiveFloat: { input: any; output: any };
  NonPositiveInt: { input: any; output: any };
  ObjectID: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Port: { input: any; output: any };
  PositiveFloat: { input: any; output: any };
  PositiveInt: { input: any; output: any };
  PostalCode: { input: any; output: any };
  RGB: { input: any; output: any };
  RGBA: { input: any; output: any };
  SafeInt: { input: any; output: any };
  Time: { input: any; output: any };
  Timestamp: { input: any; output: any };
  URL: { input: any; output: any };
  USCurrency: { input: any; output: any };
  UUID: { input: any; output: any };
  UnsignedFloat: { input: any; output: any };
  UnsignedInt: { input: any; output: any };
  Upload: { input: any; output: any };
  UtcOffset: { input: any; output: any };
  Void: { input: any; output: any };
};

export enum Address_Type {
  Airport = 'AIRPORT',
  BusinessWoutDock = 'BUSINESS_WOUT_DOCK',
  BusinessWDock = 'BUSINESS_W_DOCK',
  Construction = 'CONSTRUCTION',
  Farm = 'FARM',
  Govt = 'GOVT',
  Grocery = 'GROCERY',
  Hotel = 'HOTEL',
  LimitedAccess = 'LIMITED_ACCESS',
  Military = 'MILITARY',
  Religious = 'RELIGIOUS',
  Residential = 'RESIDENTIAL',
  School = 'SCHOOL',
  SelfStorage = 'SELF_STORAGE',
  TradeShow = 'TRADE_SHOW',
}

export type Accessorial = {
  __typename?: 'Accessorial';
  charge: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export enum Country {
  Can = 'CAN',
  Usa = 'USA',
}

export enum Customs_Terms_Of_Sale {
  Cash = 'CASH',
  Collect = 'COLLECT',
  LessPercentDiscount = 'LESS_PERCENT_DISCOUNT',
  Net_30 = 'NET_30',
  Other = 'OTHER',
  PaymentInAdvance = 'PAYMENT_IN_ADVANCE',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CarrierReliabilityScore = {
  __typename?: 'CarrierReliabilityScore';
  reliabilityScore: Scalars['Float']['output'];
  scacCode: Scalars['String']['output'];
};

export enum Distance_Uom {
  Cm = 'cm',
  In = 'in',
}

export enum Endpoint_Type {
  Destination = 'DESTINATION',
  Origin = 'ORIGIN',
}

export type EndPointAccessorialsInput = {
  appointment?: InputMaybe<Scalars['Boolean']['input']>;
  driverAssist?: InputMaybe<Scalars['Boolean']['input']>;
  inside?: InputMaybe<Scalars['Boolean']['input']>;
  liftgate?: InputMaybe<Scalars['Boolean']['input']>;
  noFreeze?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Freight_Class {
  Class_50 = 'CLASS_50',
  Class_55 = 'CLASS_55',
  Class_60 = 'CLASS_60',
  Class_65 = 'CLASS_65',
  Class_70 = 'CLASS_70',
  Class_77_5 = 'CLASS_77_5',
  Class_85 = 'CLASS_85',
  Class_92_5 = 'CLASS_92_5',
  Class_100 = 'CLASS_100',
  Class_110 = 'CLASS_110',
  Class_125 = 'CLASS_125',
  Class_150 = 'CLASS_150',
  Class_175 = 'CLASS_175',
  Class_200 = 'CLASS_200',
  Class_250 = 'CLASS_250',
  Class_300 = 'CLASS_300',
  Class_400 = 'CLASS_400',
  Class_500 = 'CLASS_500',
}

export enum Ftl_Accessorial {
  Chains = 'CHAINS',
  Coil = 'COIL',
  Det = 'DET',
  Drasst = 'DRASST',
  Edgepro = 'EDGEPRO',
  Lfc = 'LFC',
  Lft = 'LFT',
  Loadbars = 'LOADBARS',
  Loadlocks = 'LOADLOCKS',
  Loadstraps = 'LOADSTRAPS',
  Pfz = 'PFZ',
  Ppe = 'PPE',
  Tarp = 'TARP',
  Unloaddel = 'UNLOADDEL',
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type FtlEndpoint = {
  city: Scalars['String']['input'];
  country: Country;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  postalCode: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  timeZone: Scalars['Float']['input'];
};

export type FtlQuote = {
  __typename?: 'FtlQuote';
  baseRate: Scalars['Float']['output'];
  cost: Scalars['Float']['output'];
  deliveryDate: Scalars['String']['output'];
  ftlAccessorialCharges?: Maybe<Array<Accessorial>>;
  guid: Scalars['String']['output'];
  mileage: Scalars['Float']['output'];
  quoteDate: Scalars['String']['output'];
  shipmentType: Scalars['String']['output'];
  transitTime: Scalars['Int']['output'];
  truckType: Truck_Type;
};

export type FtlRfqInput = {
  accessorials?: InputMaybe<Array<Ftl_Accessorial>>;
  destination: FtlEndpoint;
  gaCid?: InputMaybe<Scalars['String']['input']>;
  origin: FtlEndpoint;
  pickupDate: Scalars['String']['input'];
  truckType: Truck_Type;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export enum Hazmat_Class {
  Hmc_1 = 'HMC_1',
  Hmc_1_1 = 'HMC_1_1',
  Hmc_1_2 = 'HMC_1_2',
  Hmc_1_3 = 'HMC_1_3',
  Hmc_1_4 = 'HMC_1_4',
  Hmc_1_5 = 'HMC_1_5',
  Hmc_1_6 = 'HMC_1_6',
  Hmc_2 = 'HMC_2',
  Hmc_2_1 = 'HMC_2_1',
  Hmc_2_2 = 'HMC_2_2',
  Hmc_2_3 = 'HMC_2_3',
  Hmc_3 = 'HMC_3',
  Hmc_4 = 'HMC_4',
  Hmc_4_1 = 'HMC_4_1',
  Hmc_4_2 = 'HMC_4_2',
  Hmc_4_3 = 'HMC_4_3',
  Hmc_5 = 'HMC_5',
  Hmc_5_1 = 'HMC_5_1',
  Hmc_5_2 = 'HMC_5_2',
  Hmc_6 = 'HMC_6',
  Hmc_6_1 = 'HMC_6_1',
  Hmc_7 = 'HMC_7',
  Hmc_8 = 'HMC_8',
  Hmc_9 = 'HMC_9',
}

export enum Item_Condition {
  New = 'NEW',
  Used = 'USED',
}

export type LtlEndpoint = {
  accessorials?: InputMaybe<EndPointAccessorialsInput>;
  addressType?: InputMaybe<Address_Type>;
  carrierAddedAccessorials?: InputMaybe<Array<Scalars['String']['input']>>;
  city: Scalars['String']['input'];
  country: Country;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  postalCode: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  timeZone: Scalars['Float']['input'];
};

export type LtlQuote = {
  __typename?: 'LtlQuote';
  baseRate: Scalars['Float']['output'];
  carrier: QuoteCarrier;
  cost: Scalars['Float']['output'];
  deliveryDate: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  ltlAccessorialCharges?: Maybe<Array<Accessorial>>;
  proposal: Proposal;
  shipmentType: Scalars['String']['output'];
  transitTime: Scalars['Int']['output'];
};

export type LtlRfqInput = {
  destination: LtlEndpoint;
  gaCid?: InputMaybe<Scalars['String']['input']>;
  items: Array<RfqItemInput>;
  origin: LtlEndpoint;
  pickupDate: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  forgotPassword: ForgotPasswordResponse;
  refreshToken?: Maybe<RefreshTokenResponse>;
  signIn?: Maybe<SignInResponse>;
  signInMerchant?: Maybe<SignInMerchantResponse>;
  signUp?: Maybe<SignUpResponse>;
  updatePassword: UpdatePasswordResponse;
  updateUserSubscription?: Maybe<UpdateUserSubscriptionResponse>;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationRefreshTokenArgs = {
  input?: InputMaybe<RefreshTokenInput>;
};

export type MutationSignInArgs = {
  input?: InputMaybe<SignInInput>;
};

export type MutationSignInMerchantArgs = {
  input?: InputMaybe<SignInMerchantInput>;
};

export type MutationSignUpArgs = {
  input?: InputMaybe<SignUpInput>;
};

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type MutationUpdateUserSubscriptionArgs = {
  input?: InputMaybe<UpdateUserSubscriptionInput>;
};

export enum Packaging_Types {
  Bags = 'BAGS',
  Bales = 'BALES',
  Barrels = 'BARRELS',
  BasketsHamper = 'BASKETS_HAMPER',
  Bottle = 'BOTTLE',
  Boxes = 'BOXES',
  Buckets = 'BUCKETS',
  Bundles = 'BUNDLES',
  Can = 'CAN',
  Carboy = 'CARBOY',
  Cartons = 'CARTONS',
  Cases = 'CASES',
  Chest = 'CHEST',
  Coils = 'COILS',
  Crates = 'CRATES',
  Cylinders = 'CYLINDERS',
  Drums = 'DRUMS',
  Envelopes = 'ENVELOPES',
  Firkin = 'FIRKIN',
  GaylordBoxes = 'GAYLORD_BOXES',
  Hogshead = 'HOGSHEAD',
  Keg = 'KEG',
  Kits = 'KITS',
  Packages = 'PACKAGES',
  Pails = 'PAILS',
  Pallet = 'PALLET',
  Pieces = 'PIECES',
  Pipe = 'PIPE',
  Rack = 'RACK',
  Reels = 'REELS',
  Rolls = 'ROLLS',
  Skids = 'SKIDS',
  Slipsheet = 'SLIPSHEET',
  Tanks = 'TANKS',
  ToteBins = 'TOTE_BINS',
  Trailer = 'TRAILER',
  Trunk = 'TRUNK',
  Tubes = 'TUBES',
}

export enum Packaging_Types_Short {
  Bag = 'BAG',
  Bal = 'BAL',
  Bbl = 'BBL',
  Bdl = 'BDL',
  Bot = 'BOT',
  Box = 'BOX',
  Bsk = 'BSK',
  Bxt = 'BXT',
  Can = 'CAN',
  Cas = 'CAS',
  Cbc = 'CBC',
  Cby = 'CBY',
  Chs = 'CHS',
  Col = 'COL',
  Crt = 'CRT',
  Ctn = 'CTN',
  Cyl = 'CYL',
  Drm = 'DRM',
  Env = 'ENV',
  Fir = 'FIR',
  Hgh = 'HGH',
  Keg = 'KEG',
  Kit = 'KIT',
  Pal = 'PAL',
  Pcs = 'PCS',
  Pkg = 'PKG',
  Pln = 'PLN',
  Plt = 'PLT',
  Rck = 'RCK',
  Rel = 'REL',
  Rol = 'ROL',
  Skd = 'SKD',
  Slp = 'SLP',
  Tbe = 'TBE',
  Tbn = 'TBN',
  Tld = 'TLD',
  Tnk = 'TNK',
  Trk = 'TRK',
}

export enum Pack_Group_Number {
  PgnI = 'PGN_I',
  PgnIi = 'PGN_II',
  PgnIii = 'PGN_III',
  PgnNa = 'PGN_NA',
}

export type PricingResult = {
  __typename?: 'PricingResult';
  accessorials?: Maybe<Array<QuoteAccessorial>>;
  carrierFinalLinehaul: Scalars['Float']['output'];
  carrierFuelSurcharge: Scalars['Float']['output'];
  carrierName: Scalars['String']['output'];
  carrierOrgId: Scalars['Int']['output'];
  carrierQuoteNumber?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  newProdLiability: Scalars['Float']['output'];
  pricingType: Scalars['String']['output'];
  profileId?: Maybe<Scalars['Int']['output']>;
  scac: Scalars['String']['output'];
  serviceType?: Maybe<Scalars['String']['output']>;
  shipperFinalLinehaul: Scalars['Float']['output'];
  shipperFuelSurcharge: Scalars['Float']['output'];
  totalMiles: Scalars['Float']['output'];
  totalShipperCost: Scalars['Float']['output'];
  transitTime: Scalars['Float']['output'];
  usedProdLiability: Scalars['Float']['output'];
};

export type Proposal = {
  __typename?: 'Proposal';
  guid: Scalars['String']['output'];
  pricingResult: PricingResult;
};

export type Query = {
  __typename?: 'Query';
  carrierReliabilityScores: Array<CarrierReliabilityScore>;
  cityStateSearch: Array<ZipAddress>;
  findReferralCode?: Maybe<ReferralCode>;
  requestFTLQuote?: Maybe<Array<FtlQuote>>;
  requestLTLQuote?: Maybe<Array<LtlQuote>>;
  requestLegacyLTLQuotes?: Maybe<Array<LtlQuote>>;
  userSubscription?: Maybe<UserSubscription>;
};

export type QueryCarrierReliabilityScoresArgs = {
  scacCodes: Array<Scalars['String']['input']>;
};

export type QueryCityStateSearchArgs = {
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type QueryFindReferralCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryRequestFtlQuoteArgs = {
  rfq: FtlRfqInput;
};

export type QueryRequestLtlQuoteArgs = {
  rfq: LtlRfqInput;
};

export type QueryRequestLegacyLtlQuotesArgs = {
  rfq: LtlRfqInput;
};

export type QueryUserSubscriptionArgs = {
  userId: Scalars['UUID']['input'];
};

export type QuoteAccessorial = {
  __typename?: 'QuoteAccessorial';
  accessorialDescription: Scalars['String']['output'];
  accessorialType: Scalars['String']['output'];
  applyBenchmarkCostBeforeFuel?: Maybe<Scalars['Boolean']['output']>;
  applyCarrierCostBeforeFuel: Scalars['Boolean']['output'];
  applyShipperCostBeforeFuel: Scalars['Boolean']['output'];
  benchmarkAccessorialCost?: Maybe<Scalars['Float']['output']>;
  carrierAccessorialCost: Scalars['Float']['output'];
  shipperAccessorialCost: Scalars['Float']['output'];
};

export type QuoteCarrier = {
  __typename?: 'QuoteCarrier';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  newLiability: Scalars['Float']['output'];
  reliabilityScore: Scalars['Float']['output'];
  usedLiability: Scalars['Float']['output'];
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  code: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referredBy: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type RfqItemInput = {
  dimWeightLbs?: InputMaybe<Scalars['Float']['input']>;
  freightClass: Freight_Class;
  hazardous: Scalars['Boolean']['input'];
  height: Scalars['Float']['input'];
  length: Scalars['Float']['input'];
  packaging: Packaging_Types;
  quantity: Scalars['Int']['input'];
  sizeUoM: Distance_Uom;
  weight: Scalars['Float']['input'];
  weightUoM: Weight_Uom;
  width: Scalars['Float']['input'];
};

export enum Shipment_Type {
  Ftl = 'FTL',
  Ltl = 'LTL',
}

export type SignInInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignInMerchantInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  shop: Scalars['String']['input'];
};

export type SignInMerchantResponse = {
  __typename?: 'SignInMerchantResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type SignUpInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  shopifyShopName?: InputMaybe<Scalars['String']['input']>;
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export enum Transaction_Type {
  Ftl = 'FTL',
  Ltl = 'LTL',
  Upcharge = 'UPCHARGE',
}

export enum Truck_Type {
  Flatbed = 'flatbed',
  Reefer = 'reefer',
  Van = 'van',
}

export type UpdatePasswordInput = {
  password: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateUserSubscriptionInput = {
  cartEmail: Scalars['Boolean']['input'];
  cartText: Scalars['Boolean']['input'];
  shipmentUpdateEmail: Scalars['Boolean']['input'];
  shipmentUpdateText: Scalars['Boolean']['input'];
  userId: Scalars['UUID']['input'];
};

export type UpdateUserSubscriptionResponse = {
  __typename?: 'UpdateUserSubscriptionResponse';
  error?: Maybe<Scalars['String']['output']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  cartEmail: Scalars['Boolean']['output'];
  cartText: Scalars['Boolean']['output'];
  shipmentUpdateEmail: Scalars['Boolean']['output'];
  shipmentUpdateText: Scalars['Boolean']['output'];
};

export enum Weight_Uom {
  Kg = 'kg',
  Lbs = 'lbs',
}

export type ZipAddress = {
  __typename?: 'ZipAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  timeOffset?: Maybe<Scalars['Float']['output']>;
};
