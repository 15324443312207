import dayjs from 'dayjs';
import { lowerCase, map, startCase, capitalize } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  AllCommonAccessorials,
  AllEndpointAccessorials,
  EndPointAccessorialDescriptionByTruckType,
  getSelectedAccessorials,
  LTLDestinationEndPointAccessorials,
  LTLOriginEndPointAccessorials,
  Accessorials,
  AccessorialDescriptionByTruckType,
} from '@/utils/Accessories';
import { DistanceInInches, WeightInLbs } from '@/utils/calculateClass';
import {
  Country,
  DistanceUoM,
  WeightUoM,
  FreightClass,
  RFQ,
  Item,
  AddressType,
  HazMatDetails,
  ItemCondition,
  LTLQuote,
  FTLQuote,
  BookingRequest,
  Quote,
  PaymentInfo,
  CostBreakdown,
  AddressSummary,
  FullAddress,
  Booking,
  LoadDetails,
  LoadStatus,
  FTL_RFQ,
  isLtlQuote,
  TruckType,
  FullEndPoint,
  UpchargeDetails,
  Customs,
  BrokerAddress,
  TerminalInfo,
  CarrierSummary,
  AuthUser,
  AccessorialCharge,
  LTL_RFQ,
  HazardousItem,
} from '@/utils/types';

import {
  FtlRfqInput,
  Ftl_Accessorial,
  Truck_Type,
  Country as GraphCountry,
  Accessorial,
} from '../publicTypes.d';
import {
  Address_Type,
  Distance_Uom,
  Freight_Class,
  LtlQuote,
  LtlRfqInput,
  Packaging_Types,
  RfqItemInput,
  Shipment_Type,
  Weight_Uom,
} from '../types.d';

import { GetLegacyBookings_viewer_legacyBookings } from './graphql/address/__generated__/GetLegacyBookings';
import {
  RequestFTLQuote_requestFTLQuote,
  RequestFTLQuote_requestFTLQuote_ftlAccessorialCharges,
} from './graphql/quotes/__generated__/RequestFTLQuote';

type Empty = Record<string, never>;

export type RawAddress = {
  city: string;
  prefCity: string;
  zip: string;
  state: string;
  stateName: string;
  countryCode: string;
  zoneOffset?: number;
};

type RawHazardousDetails = {
  emergencyCompany?: string;
  emergencyContract?: string;
  emergencyPhone?: Phone;
  hazmatClass: string;
  hazmatInstruction?: string;
  rq: boolean;
  unNumber: string;
  packingGroup: string;
};

type RawItem = {
  dim_size: DistanceUoM;
  dim_weight: WeightUoM;
  quantity?: number;
  productPackage: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  totalWeight: number;
  productClass: FreightClass;
  value: number;
  itemCondition: string;
  hazardous?: boolean;
  dimWeightLbs?: number;
} & Partial<RawHazardousDetails>;

type RawShipmentItem = {
  quantity: number;
  newItem: boolean;
  hazardous: boolean;
  stackable: boolean;
  dim_size: DistanceUoM;
  dim_weight: WeightUoM;
  pieces: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  value: number;
  description: string;
  productClass: FreightClass;
  productPackage: string;
  tariff?: string;
  countryOrigin?: string;
} & Partial<RawHazardousDetails>;

type RawLocationType =
  | 'LT1'
  | 'LT2'
  | 'LT3'
  | 'LT4'
  | 'LT5'
  | 'LT7'
  | 'LT8'
  | 'LT9'
  | 'LT10'
  | 'LT11'
  | 'LT12'
  | 'LT13'
  | 'LT15'
  | 'LT16';

type RawEndpoint = {
  address1?: string;
  address2?: string | null;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: Phone;
  instructions?: string;
  endTime?: string;
  name?: string;
  saveToAddressBook?: boolean;
  startTime?: string;
  userSelectedLiftgate: boolean;
  zip: RawZipAddress;
  country: string;
  locationType: RawLocationType;
};

type RawAccessorials =
  // residential pickup
  | 'REP'
  // liftgate pickup
  | 'LFC'
  // inside pickup
  | 'IPU'
  //over dimension
  | 'ODM'
  // blind BOL
  | 'BLB'
  // limited access pickup
  | 'LAP'
  // residential delivery
  | 'RES'
  // liftgate delivery
  | 'LFT'
  // inside delivery
  | 'IDL'
  // sort & segregate delivery
  | 'SSD'
  // notify delivery (appt)
  | 'NDR'
  // limited access delivery
  | 'LAD'
  // Hazmat
  | 'HZC';

type RawShipmentRequest = {
  errorOccured: boolean;
  errorMsg: string;
  origin: RawEndpoint;
  destination: RawEndpoint;
  customs: {
    broker: RawFullAddress | Empty;
    vendor: RawFullAddress | Empty;
    purchaser: RawFullAddress | Empty;
  };
  insurance: Empty;
  uom: {
    size: DistanceUoM;
    weight: WeightUoM;
  };
  additionalServices: RawAccessorials[];
  pickupDate: string;
  items: RawItem[];
  guid: string;
  wizardStep: number;
  wizardType: string;
  selectedTruckType: null | string;
  dim_weight: string;
  dim_size: string;
  transCountry: boolean;
  orderComplete: boolean;
  loadServiceTypeIds: string[];
  termsOfSale: string;
  version: number;
  equipmentTemperature: null | string;
  requester: string;
  wizardDataId: null | string;
  quotes: [];
  isThirdPartyRedirect: true;
  orderDate: number;
  source: 'CUTTLEFISH';
  abandonCartUrl?: boolean;
  quote: {
    carrier?: {
      id: number;
      name: string;
      newLiability: string;
      usedLiability: string;
    };
    month?: number;
    monthText?: string;
    quoteId?: number;
    ranking?: number;
    weekDay?: string;
    cost?: number;
    deliveryDate: string | null;
    guid?: string;
    transitTime?: number;
    quoteDate?: string;
  };
  ltlLifecycleTimeoutSeconds: number;
};
export type RawLtlRFQ = RawShipmentRequest;
export type RawFtlRFQ = {
  shipment: RawShipmentRequest;
  quoteDate: string;
  daysReturned: number;
};

export type RawProposal = {
  guid: string;
  pricingResult: {
    profileId?: number | null;
    carrierName: string;
    scac: string;
    pricingType: string;
    serviceType?: string | null;
    newProdLiability: number;
    usedProdLiability: number;
    transitTime: number;
    totalMiles: number;
    currencyCode: string;
    carrierFinalLinehaul: number;
    carrierFuelSurcharge: number;
    shipperFinalLinehaul: number;
    shipperFuelSurcharge: number;
    totalShipperCost: number;
    accessorials?:
      | {
          accessorialType: string;
          accessorialDescription: string;
          carrierAccessorialCost: number;
          applyCarrierCostBeforeFuel: boolean;
          shipperAccessorialCost: number;
          applyShipperCostBeforeFuel: boolean;
          benchmarkAccessorialCost?: number | null;
          applyBenchmarkCostBeforeFuel?: boolean | null;
        }[]
      | null;
    carrierQuoteNumber?: string | null;
  };
};

export type RawPlsLTLQuote = {
  propositions: RawProposal[];
  quotes: {
    guid: string;
    transitTime: number;
    deliveryDate: string;
    cost: number;
    carrier: {
      id: number;
      name: string;
      newLiability: number;
      usedLiability: number;
    };
  }[];
};

export type AuthToken = {
  personId: number;
  accessToken: string;
  refreshToken: string;
};

export type SaveUser = {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  phoneNumber: Phone;
  password: string;
};

export type RawPlsFTLQuote = {
  cost: number;
  quoteDate: string;
  ranking: number;
  deliveryDate: string;
  quoteId: number;
  weekDay: string;
  monthText: string;
  month: number;
  year: number;
  guid: string;
};

export type BillingDetails = {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  billingAddress: string;
  zip?: RawZipAddress;
};

export type RawZipAddress = {
  city: string;
  prefCity: string;
  zip: string;
  state: string;
  timeZone: string;
  country: CountryBO;
  lat: number;
  lng: number;
};

export type CountryBO = {
  id: string;
  name: string;
  dialingCode: string;
};

export type HourMinutes = {
  hours: number;
  minutes: number;
};

export type Phone = {
  areaCode: string;
  countryCode: string;
  number: string;
  extension?: string;
};

export type RawFullAddress = {
  name: string;
  address1: string;
  address2: string | null;
  zip: RawZipAddress;
  contactPhone: Phone;
  contactName?: string;
  contactEmail: string;
  instructions?: string;
  locationType?: string;
  saveToAddressBook: boolean;
};

export type AddressWithScheduledTime = {
  startTimeHrsMins: HourMinutes;
  endTimeHrsMins: HourMinutes;
} & RawFullAddress;

export type PromoCode = {
  discount: number;
  // Is `cost` this needed client-side?
  cost?: number;
  code?: string;
};

export type Insurance = {
  personID: string;
  amountInsured: string;
  premium: number;
  excludedItemIDs: string;
  merchDescriptions: string;
  insureLoad: boolean;
};

type RawBrokerAddress = (
  | (RawFullAddress & { usePlsBroker: false })
  | { usePlsBroker: true }
) & {
  usePlsBroker: boolean;
  brokerAuthorized: true;
  createCustomsInvoice: boolean;
  country: Country;
};

type RawCustoms = {
  broker: RawBrokerAddress;
  vendor?: {
    country: Country;
    usePickup: boolean;
    useDelivery: boolean;
    companyName: string;
    contactEmail: string;
    contactName: string;
    contactPhone: Phone;
    address1: string;
    address2: string;
    fax: Phone;
    zip: RawZipAddress;
    locationType: string;
  };
  purchaser?: {
    country: Country;
    usePickup: boolean;
    useDelivery: boolean;
    companyName: string;
    contactEmail: string;
    contactName: string;
    contactPhone: Phone;
    address1: string;
    address2: string;
    fax: Phone;
    zip: RawZipAddress;
    locationType: string;
  };
};

type BookingShipment = {
  additionalServices: string[];
  billingDetails?: BillingDetails;
  customs: RawCustoms;
  destination: AddressWithScheduledTime;
  guid: string;
  insurance: Insurance;
  items: RawShipmentItem[];
  origin: AddressWithScheduledTime;
  pickupDate: string;
  proposals: RawProposal[];
  orderDate: string;
  pickupDateEnd: string;
  promoCode?: PromoCode;
  quote: Quote;
  transCountry: boolean;
  termsOfSale: string;
  termsAccepted: boolean;
  transactionId: string | null;
  originationId: string | null;
  requester: string;
  wizardDataId: string;
  wizardType: string;
  isThirdPartyRedirect: boolean;
  finalCost: number;
  loadType: string;
  selectedTruckType?: string;
  equipmentTemperature: number;
  loadServiceTypeIds: undefined | number[];
  creditAmount: number;
  creditTransactionId?: string;
  ftlAccessorialCharges?: { charge: number; accessorialTypeId: number }[];
};

export type IpLocation = {
  ip: string;
  country: string;
  city: string;
  state: string;
};

const convertConditionToShipment = (c: ItemCondition) => c === 'NEW';

const convertHazMatDetailsToRaw = ({
  reportableQty,
  unNumber,
  packGroupNumber,
  emergencyContactCompany,
  emergencyContactPhone,
  hazmatClass,
  contractNumber,
  instructions,
}: HazMatDetails): RawHazardousDetails => ({
  rq: reportableQty,
  emergencyCompany: emergencyContactCompany,
  emergencyContract: contractNumber,
  emergencyPhone: emergencyContactPhone
    ? convertPhoneToRaw(emergencyContactPhone)
    : undefined,
  hazmatClass,
  packingGroup: packGroupNumber,
  unNumber,
  hazmatInstruction: instructions,
});

const convertItemToRawShipmentItem = ({
  quantity,
  sizeUoM,
  length,
  width,
  height,
  weightUoM,
  weight,
  freightClass,
  value,
  itemCondition,
  hazardous,
  stackable,
  packaging,
  description,
  countryOfOrigin,
  hazmatDetails,
}: Item): RawShipmentItem => ({
  quantity: quantity || 0,
  dim_size: 'in',
  length: DistanceInInches.to(length || 0, sizeUoM),
  width: DistanceInInches.to(width || 0, sizeUoM),
  height: DistanceInInches.to(height || 0, sizeUoM),
  dim_weight: 'lbs',
  weight: WeightInLbs.to(weight || 0, weightUoM),
  productClass: freightClass,
  value: value || 0,
  newItem: convertConditionToShipment(itemCondition),
  pieces: quantity || 0,
  hazardous,
  stackable,
  description,
  productPackage: packaging,
  countryOrigin: countryOfOrigin,
  ...(hazardous && hazmatDetails
    ? convertHazMatDetailsToRaw(hazmatDetails)
    : {}),
});

const AccessorialTypeMap: { [key in string]: number } = {
  DRASST: 60,
  PFZ: 70,
  LOADBARS: 60,
  LOADLOCKS: 60,
  LOADSTRAPS: 60,
  UNLOADDEL: 175,
  DET: 24,
  EDGEPRO: 60,
  TARP: 105,
  COIL: 60,
  CHAINS: 60,
  LFC: 53,
  LFT: 52,
  HAZM: 46,
};

const AddressTypeMap: { [key in AddressType]: RawLocationType } = {
  RESIDENTIAL: 'LT1',
  BUSINESS_W_DOCK: 'LT2',
  BUSINESS_WOUT_DOCK: 'LT3',
  FARM: 'LT4',
  SELF_STORAGE: 'LT5',
  CONSTRUCTION: 'LT7',
  SCHOOL: 'LT8',
  GOVT: 'LT9',
  RELIGIOUS: 'LT10',
  HOTEL: 'LT11',
  MILITARY: 'LT12',
  AIRPORT: 'LT13',
  TRADE_SHOW: 'LT15',
  GROCERY: 'LT16',
  LIMITED_ACCESS: 'LT4',
};

const digitsOnly = (s: string) =>
  s
    .split('')
    .filter(c => /\d/.exec(c))
    .join('');

const dropLeading1 = (s: string) => (s.charAt(0) === '1' ? s.slice(1) : s);

const convertPhoneToRaw = (fullPhone: string) => {
  const digits = digitsOnly(fullPhone);

  const phone = dropLeading1(digits);
  return {
    areaCode: phone.slice(0, 3),
    countryCode: '1',
    number: phone.slice(3),
    extension: '',
  };
};

const makeDateFormat = (f: string): ((d: Date) => string) => (d: Date) =>
  dayjs(d).format(f);

const asDate = makeDateFormat('YYYY-MM-DD');

const convertTruckType = (truckType: TruckType) => {
  if (truckType === 'van') return Truck_Type.Van;
  if (truckType === 'reefer') return Truck_Type.Reefer;
  if (truckType === 'flatbed') return Truck_Type.Flatbed;
  return Truck_Type.Van;
};

const getFTLAccessorials = (rfq: RFQ) => {
  const ftlAccessorials: Ftl_Accessorial[] = [];
  const commonAccessorial = rfq.commonAccessorials;
  if (rfq.origin.accessorials.driverAssist) {
    ftlAccessorials.push(Ftl_Accessorial.Drasst);
  }
  if (rfq.origin.accessorials.liftgate) {
    ftlAccessorials.push(Ftl_Accessorial.Lfc);
  }
  if (rfq.destination.accessorials.liftgate) {
    ftlAccessorials.push(Ftl_Accessorial.Lft);
  }
  if (rfq.destination.accessorials.driverAssist) {
    ftlAccessorials.push(Ftl_Accessorial.Unloaddel);
  }
  if (commonAccessorial.loadBars) {
    ftlAccessorials.push(Ftl_Accessorial.Loadbars);
  }
  if (commonAccessorial.loadLocks) {
    ftlAccessorials.push(Ftl_Accessorial.Loadlocks);
  }
  if (commonAccessorial.straps) {
    ftlAccessorials.push(Ftl_Accessorial.Loadstraps);
  }
  if (commonAccessorial.ppe) {
    ftlAccessorials.push(Ftl_Accessorial.Ppe);
  }
  if (commonAccessorial.noFreeze) {
    ftlAccessorials.push(Ftl_Accessorial.Pfz);
  }
  if (commonAccessorial.tarps) {
    ftlAccessorials.push(Ftl_Accessorial.Tarp);
  }
  if (commonAccessorial.chains) {
    ftlAccessorials.push(Ftl_Accessorial.Chains);
  }
  if (commonAccessorial.coilRacks) {
    ftlAccessorials.push(Ftl_Accessorial.Coil);
  }
  if (commonAccessorial.edgeProtectors) {
    ftlAccessorials.push(Ftl_Accessorial.Edgepro);
  }
  return ftlAccessorials;
};

export const convertFtlRfqToQuery = (rfq: FTL_RFQ): FtlRfqInput => ({
  truckType: convertTruckType(rfq.truckType),
  origin: {
    city: rfq.origin.address.city,
    postalCode: rfq.origin.address.postalCode,
    stateCode: rfq.origin.address.state,
    timeZone: rfq.origin.address.timeOffset,
    country:
      rfq.origin.address.country === 'USA'
        ? GraphCountry.Usa
        : GraphCountry.Can,
    lat: rfq.origin.address.lat,
    lng: rfq.origin.address.lng,
  },
  destination: {
    city: rfq.destination.address.city,
    postalCode: rfq.destination.address.postalCode,
    stateCode: rfq.destination.address.state,
    timeZone: rfq.destination.address.timeOffset,
    country:
      rfq.destination.address.country === 'USA'
        ? GraphCountry.Usa
        : GraphCountry.Can,
    lat: rfq.destination.address.lat,
    lng: rfq.destination.address.lng,
  },
  pickupDate: asDate(rfq.origin.date),
  accessorials: getFTLAccessorials(rfq),
});

export const convertQueryFTLQuote = (
  rqs: RequestFTLQuote_requestFTLQuote[],
): FTLQuote[] =>
  rqs?.map(rfq => ({
    guid: rfq.guid,
    truckType: rfq.truckType,
    transitTime: rfq.transitTime,
    cost: rfq.cost,
    baseRate: rfq.baseRate,
    deliveryDate: convertRawDate(rfq.deliveryDate),
    pickupDate: convertRawDate(rfq.quoteDate),
    shipmentType: Shipment_Type.Ftl,
    ftlAccessorialCharges: convertFtlAccessorialCharges(
      rfq.ftlAccessorialCharges,
    ),
  })) || [];

export const convertFtlAccessorialCharges = (
  ftlAcceorialCharges:
    | RequestFTLQuote_requestFTLQuote_ftlAccessorialCharges[]
    | null,
): AccessorialCharge[] =>
  ftlAcceorialCharges?.map(acc => ({
    charge: acc.charge,
    description: acc.description,
    code: acc.code,
  })) || [];

export const convertLtlAccessorialCharges = (
  ltlAccessorialCharges?: Accessorial[] | null,
): AccessorialCharge[] =>
  ltlAccessorialCharges?.map(acc => ({
    charge: acc.charge,
    description: acc.description,
    code: acc.code,
  })) || [];

export const convertQueryLTLQuote = (rqs: LtlQuote[]): LTLQuote[] =>
  rqs?.map(rfq => ({
    guid: rfq.guid,
    transitTime: rfq.transitTime,
    cost: rfq.cost,
    baseRate: rfq.baseRate,
    deliveryDate: convertRawDate(rfq.deliveryDate),
    shipmentType: Shipment_Type.Ltl,
    proposal: rfq.proposal,
    carrier: {
      name: rfq.carrier.name,
      newLiability: rfq.carrier.newLiability,
      usedLiability: rfq.carrier.usedLiability,
      reliabilityScore: rfq.carrier.reliabilityScore,
    },
    ltlAccessorialCharges: convertLtlAccessorialCharges(
      rfq.ltlAccessorialCharges,
    ),
  })) || [];

const convertRawDate = (s: string, offset = 0): Date =>
  dayjs(s, 'YYYY-MM-DD').add(offset, 'days').toDate();

export const convertLtlRfqToQuery = (rfq: LTL_RFQ): LtlRfqInput => ({
  origin: {
    city: rfq.origin.address.city,
    postalCode: rfq.origin.address.postalCode,
    stateCode: rfq.origin.address.state,
    timeZone: rfq.origin.address.timeOffset,
    country:
      rfq.origin.address.country === 'USA'
        ? GraphCountry.Usa
        : GraphCountry.Can,
    lat: rfq.origin.address.lat,
    lng: rfq.origin.address.lng,
    addressType: convertRawAddressType(rfq.origin.type),
    accessorials: rfq.origin.accessorials,
    carrierAddedAccessorials: rfq.origin.carrierAddedAccessorials,
  },
  destination: {
    city: rfq.destination.address.city,
    postalCode: rfq.destination.address.postalCode,
    stateCode: rfq.destination.address.state,
    timeZone: rfq.destination.address.timeOffset,
    country:
      rfq.destination.address.country === 'USA'
        ? GraphCountry.Usa
        : GraphCountry.Can,
    lat: rfq.destination.address.lat,
    lng: rfq.destination.address.lng,
    addressType: convertRawAddressType(rfq.destination.type),
    accessorials: rfq.destination.accessorials,
    carrierAddedAccessorials: rfq.destination.carrierAddedAccessorials,
  },
  pickupDate: asDate(rfq.origin.date),
  items: convertItemToRfqItemInput(rfq.items),
});

const convertItemToRfqItemInput = (
  items: Array<HazardousItem | Item>,
): RfqItemInput[] =>
  items?.map(item => ({
    quantity: item.quantity || 0,
    packaging: convertRawPacketType(item.packaging),
    sizeUoM: item.sizeUoM === 'cm' ? Distance_Uom.Cm : Distance_Uom.In,
    length: item.length || 0,
    width: item.width || 0,
    height: item.height || 0,
    weightUoM: item.weightUoM === 'kg' ? Weight_Uom.Kg : Weight_Uom.Lbs,
    weight: item.weight || 0,
    freightClass: convertRawFreightClass(item.freightClass),
    hazardous: item.hazardous,
  }));

export const convertRawPacketType = (packetType: string): Packaging_Types => {
  const packageTypeMap: { [key in string]: Packaging_Types } = {
    PLT: Packaging_Types.Pallet,
    CRT: Packaging_Types.Crates,
    BOX: Packaging_Types.Boxes,
    BAG: Packaging_Types.Bags,
    BAL: Packaging_Types.Bales,
    BBL: Packaging_Types.Barrels,
    BSK: Packaging_Types.BasketsHamper,
    BOT: Packaging_Types.Bottle,
    BDL: Packaging_Types.Bundles,
    CAN: Packaging_Types.Can,
    CBY: Packaging_Types.Carboy,
    CTN: Packaging_Types.Cartons,
    CAS: Packaging_Types.Cases,
    CHS: Packaging_Types.Chest,
    COL: Packaging_Types.Coils,
    CYL: Packaging_Types.Cylinders,
    DRM: Packaging_Types.Drums,
    ENV: Packaging_Types.Envelopes,
    FIR: Packaging_Types.Firkin,
    CBC: Packaging_Types.GaylordBoxes,
    HGH: Packaging_Types.Hogshead,
    KEG: Packaging_Types.Keg,
    KIT: Packaging_Types.Kits,
    PKG: Packaging_Types.Packages,
    PAL: Packaging_Types.Pails,
    PCS: Packaging_Types.Pieces,
    PLN: Packaging_Types.Pipe,
    RCK: Packaging_Types.Rack,
    REL: Packaging_Types.Reels,
    ROL: Packaging_Types.Rolls,
    SKD: Packaging_Types.Skids,
    SLP: Packaging_Types.Slipsheet,
    TNK: Packaging_Types.Tanks,
    TBN: Packaging_Types.ToteBins,
    TLD: Packaging_Types.Trailer,
    TRK: Packaging_Types.Trunk,
    TBE: Packaging_Types.Tubes,
  };
  return packageTypeMap[packetType];
};

export const convertRawAddressType = (addressType: string): Address_Type => {
  const addressTypeMap: { [key in string]: Address_Type } = {
    AIRPORT: Address_Type.Airport,
    BUSINESS_WOUT_DOCK: Address_Type.BusinessWoutDock,
    BUSINESS_W_DOCK: Address_Type.BusinessWDock,
    CONSTRUCTION: Address_Type.Construction,
    FARM: Address_Type.Farm,
    GOVT: Address_Type.Govt,
    GROCERY: Address_Type.Grocery,
    HOTEL: Address_Type.Hotel,
    LIMITED_ACCESS: Address_Type.LimitedAccess,
    MILITARY: Address_Type.Military,
    RELIGIOUS: Address_Type.Religious,
    RESIDENTIAL: Address_Type.Residential,
    SCHOOL: Address_Type.School,
    SELF_STORAGE: Address_Type.SelfStorage,
    TRADE_SHOW: Address_Type.TradeShow,
  };
  return addressTypeMap[addressType];
};

export const convertRawFreightClass = (freightClass: string): Freight_Class => {
  const freightTypeMap: { [key in string]: Freight_Class } = {
    CLASS_50: Freight_Class.Class_50,
    CLASS_55: Freight_Class.Class_55,
    CLASS_60: Freight_Class.Class_60,
    CLASS_65: Freight_Class.Class_65,
    CLASS_70: Freight_Class.Class_70,
    CLASS_77_5: Freight_Class.Class_77_5,
    CLASS_85: Freight_Class.Class_85,
    CLASS_92_5: Freight_Class.Class_92_5,
    CLASS_100: Freight_Class.Class_100,
    CLASS_110: Freight_Class.Class_110,
    CLASS_125: Freight_Class.Class_125,
    CLASS_150: Freight_Class.Class_150,
    CLASS_175: Freight_Class.Class_175,
    CLASS_200: Freight_Class.Class_200,
    CLASS_250: Freight_Class.Class_250,
    CLASS_300: Freight_Class.Class_300,
    CLASS_400: Freight_Class.Class_400,
    CLASS_500: Freight_Class.Class_500,
  };
  return freightTypeMap[freightClass];
};

/**
 * Little hack: we are hiding some backend information in the Quote object,
 * because we will need it to prepare the booking-request
 */

type BackendQuote = {
  proposal: RawProposal | undefined;
};

const isBackendQuote = (q: any): q is BackendQuote => !!q.proposal;

// strip out the proposal
const convertToRawQuote = (q: Quote): Quote =>
  (({
    ...q,
    proposal: undefined,
  } as unknown) as Quote);

const getSelectedAccessorialIds = (
  ...args: Parameters<typeof getSelectedAccessorials>
) => getSelectedAccessorials(...args).map(({ id }) => id);

const getRawLtlAdditionalServices = (bookingRequest: BookingRequest) => [
  ...getSelectedAccessorialIds(
    LTLOriginEndPointAccessorials,
    bookingRequest.origin.accessorials,
    AllEndpointAccessorials,
  ),
  ...getSelectedAccessorialIds(
    LTLDestinationEndPointAccessorials,
    bookingRequest.destination.accessorials,
    AllEndpointAccessorials,
  ),
];

const getLoadServiceTypeIds = (
  bookingRequest: BookingRequest,
  truckType: TruckType,
) =>
  [
    ...getSelectedAccessorialIds(
      AccessorialDescriptionByTruckType[truckType],
      bookingRequest.commonAccessorials as Accessorials,
      AllCommonAccessorials,
    ),
    ...getSelectedAccessorialIds(
      EndPointAccessorialDescriptionByTruckType['origin'],
      bookingRequest.origin.accessorials,
      AllEndpointAccessorials,
    ),
    ...getSelectedAccessorialIds(
      EndPointAccessorialDescriptionByTruckType['destination'],
      bookingRequest.destination.accessorials,
      AllEndpointAccessorials,
    ),
  ].map(Number);

const convertAddressToRawZipAddress = (
  address: FullAddress,
): RawZipAddress => ({
  city: address.city,
  prefCity: address.city,
  zip: address.postalCode,
  state: address.state,
  timeZone: address.timeOffset.toString(),
  country: {
    id: address.country,
    name: address.country,
    dialingCode: '',
  },
  lat: address.lat,
  lng: address.lng,
});
const convertFullAddressToRawFullAddress = (
  address: FullAddress,
): RawFullAddress => ({
  name: address.companyName,
  address1: address.streetAddress,
  address2: address.streetAddress1,
  zip: convertAddressToRawZipAddress(address),
  contactPhone: convertPhoneToRaw(address.phone),
  contactName:
    address?.firstName && address?.lastName
      ? address.firstName + ' ' + address.lastName
      : undefined,
  contactEmail: address.email,
  saveToAddressBook: false,
});

const convertFullEndPointToRawFullAddress = (
  endpoint: FullEndPoint,
): RawFullAddress => ({
  ...convertFullAddressToRawFullAddress(endpoint.address),
  locationType: AddressTypeMap[endpoint.type],
  instructions: endpoint.instructions,
});

const convertBrokerAddressToRaw = (
  brokerAddress: BrokerAddress,
  createCustomsInvoice: boolean,
  country: Country,
): RawBrokerAddress =>
  brokerAddress.usePlsBroker
    ? {
        usePlsBroker: true,
        brokerAuthorized: true,
        createCustomsInvoice,
        country,
      }
    : {
        ...convertFullAddressToRawFullAddress(brokerAddress),
        usePlsBroker: false,
        brokerAuthorized: true,
        createCustomsInvoice,
        country: brokerAddress.country,
      };

const convertCustomsToRaw = (
  customs: Customs,
  country: Country,
  transCountry: boolean,
): RawCustoms => {
  const blankCustoms: RawCustoms = {
    broker: {
      usePlsBroker: true,
      brokerAuthorized: true,
      createCustomsInvoice: false,
      country,
    },
  };

  const rawCustoms: RawCustoms = {
    ...blankCustoms,
    vendor: {
      country: customs.vendor.country,
      usePickup: customs.vendor.usePickup,
      useDelivery: customs.vendor.useDelivery,
      companyName: customs.vendor.companyName,
      contactEmail: customs.vendor.email,
      contactName: customs.vendor.firstName + ' ' + customs.vendor.lastName,
      contactPhone: convertPhoneToRaw(customs.vendor.phone),
      address1: customs.vendor.streetAddress,
      address2: customs.vendor.streetAddress1 || '',
      fax: {
        countryCode: '',
        areaCode: '',
        number: '',
      },
      zip: {
        city: customs.vendor.city,
        prefCity: customs.vendor.city,
        zip: customs.vendor.postalCode,
        state: customs.vendor.state,
        timeZone: '',
        country: {
          id: convertRawCountry(customs.vendor.country),
          name: convertRawCountry(customs.vendor.country),
          dialingCode: '',
        },
        lat: customs.vendor.lat,
        lng: customs.vendor.lng,
      },
      locationType: '',
    },
    purchaser: {
      country: customs.purchaser.country,
      usePickup: customs.purchaser.usePickup,
      useDelivery: customs.purchaser.useDelivery,
      companyName: customs.purchaser.companyName,
      contactEmail: customs.purchaser.email,
      contactName:
        customs.purchaser.firstName + ' ' + customs.purchaser.lastName,
      contactPhone: convertPhoneToRaw(customs.purchaser.phone),
      address1: customs.purchaser.streetAddress,
      address2: customs.purchaser.streetAddress1 || '',
      fax: {
        countryCode: '',
        areaCode: '',
        number: '',
      },
      zip: {
        city: customs.purchaser.city,
        prefCity: customs.purchaser.city,
        zip: customs.purchaser.postalCode,
        state: customs.purchaser.state,
        timeZone: '',
        country: {
          id: convertRawCountry(customs.purchaser.country),
          name: convertRawCountry(customs.purchaser.country),
          dialingCode: '',
        },
        lat: customs.purchaser.lat,
        lng: customs.purchaser.lng,
      },
      locationType: '',
    },
  };

  if (!transCountry) {
    return blankCustoms;
  }

  return {
    ...rawCustoms,
    broker: convertBrokerAddressToRaw(
      customs.broker,
      customs.createCustomsInvoice,
      country,
    ),
  };
};

export const convertRawBookingRequest = (
  bookingRequest: BookingRequest,
  costBreakdown: CostBreakdown,
  paymentInfo: PaymentInfo,
  payingWithCreditInFull: boolean,
  user: AuthUser | null,
): BookingShipment => {
  const {
    quote,
    origin,
    destination,
    billing,
    items,
    bookingDetails,
    customs,
  } = bookingRequest;

  const truckType = isLtlQuote(quote) ? undefined : quote.truckType || 'van'; // supposed to be set already
  const equipmentTemperature =
    isLtlQuote(quote) || quote.truckType !== 'reefer'
      ? 0
      : bookingRequest.commonAccessorials.temperature || 0;

  const proposal = isBackendQuote(quote) ? quote.proposal : null;
  const proposals = proposal ? [proposal] : [];
  const additionalServices = truckType
    ? []
    : getRawLtlAdditionalServices(bookingRequest);
  const loadServiceTypeIds = truckType
    ? getLoadServiceTypeIds(bookingRequest, truckType)
    : undefined;

  const convertBookingEndPointToRaw = (
    endpoint: FullEndPoint,
  ): AddressWithScheduledTime => ({
    ...convertFullEndPointToRawFullAddress(endpoint),
    startTimeHrsMins: {
      hours: dayjs(endpoint.startTime).hour(),
      minutes: dayjs(endpoint.startTime).minute(),
    },
    endTimeHrsMins: {
      hours: dayjs(endpoint.endTime).hour(),
      minutes: dayjs(endpoint.endTime).minute(),
    },
    instructions: endpoint.instructions,
  });

  const transCountry = destination.address.country !== origin.address.country;

  const rawDestination = {
    ...convertBookingEndPointToRaw(destination),
    instructions:
      transCountry &&
      customs.broker.usePlsBroker &&
      customs.broker.brokerAuthorized
        ? `${destination.instructions} Clearit Contact: Phone: 1-888-668-7595, Fax: 1-844-999-9111, PAPS E-mail: paps@clearitusa.com, Send documents to: documents@clearitusa.com`
        : destination.instructions,
  };

  const rawItems = items.map(convertItemToRawShipmentItem);

  const ftlQuote = truckType ? (quote as FTLQuote) : undefined;

  const ftlAccessorialCharges = ftlQuote?.ftlAccessorialCharges?.map(
    ftlAccessorialCharge => ({
      charge: ftlAccessorialCharge.charge,
      accessorialTypeId: AccessorialTypeMap[ftlAccessorialCharge.code],
    }),
  );

  const miscFtlAccessorialCharges = ftlAccessorialCharges?.filter(
    ftlAccessorialCharge => ftlAccessorialCharge.accessorialTypeId === 60,
  );
  const remainingFtlAccessorialCharges = ftlAccessorialCharges?.filter(
    ftlAccessorialCharge => ftlAccessorialCharge.accessorialTypeId !== 60,
  );

  let totalMiscCharge = 0;
  miscFtlAccessorialCharges?.forEach(misc => (totalMiscCharge += +misc.charge));

  if (totalMiscCharge !== 0)
    remainingFtlAccessorialCharges?.push({
      charge: totalMiscCharge,
      accessorialTypeId: 60,
    });

  const shipment: BookingShipment = {
    additionalServices,
    loadServiceTypeIds,
    billingDetails: {
      fullName:
        billing?.address.firstName && billing?.address.lastName
          ? billing.address.firstName + ' ' + billing.address.lastName
          : undefined,
      billingAddress: billing.address.streetAddress,
      zip: convertAddressToRawZipAddress(billing.address),
    },
    customs: convertCustomsToRaw(
      customs,
      destination.address.country,
      transCountry,
    ),
    destination: rawDestination,
    origin: convertBookingEndPointToRaw(origin),
    items:
      truckType === 'reefer'
        ? rawItems.map(rawItem => ({
            ...rawItem,
            description: `${rawItem.description}, Temperature: ${bookingRequest.commonAccessorials.temperature} °F`,
          }))
        : rawItems,
    proposals: proposals,
    guid: quote.guid,
    insurance: {
      personID: `${user?.personId}`,
      amountInsured: `${bookingDetails.insuredValue}`,
      premium: costBreakdown.premium,
      excludedItemIDs: '',
      merchDescriptions: map(items, 'description').join(', '),
      insureLoad: bookingDetails.insuredValue > 0,
    },
    pickupDate: asDate(origin.date),
    orderDate: '',
    pickupDateEnd: '',
    promoCode:
      bookingDetails.promoCode && !costBreakdown?.creditAmount
        ? {
            discount: costBreakdown.promoCodeDiscount,
          }
        : undefined,
    quote: convertToRawQuote(quote),
    transCountry,
    termsOfSale: customs?.termsOfSale || '',
    termsAccepted: true,
    transactionId: payingWithCreditInFull ? null : paymentInfo?.id,
    originationId: payingWithCreditInFull ? null : paymentInfo?.token,
    requester: 'goshipui',
    wizardDataId: '',
    wizardType: quote.shipmentType,
    isThirdPartyRedirect: true,
    finalCost: costBreakdown.finalCost,
    loadType: quote.shipmentType,
    selectedTruckType: truckType && capitalize(truckType),
    equipmentTemperature,
    creditAmount: costBreakdown.creditAmount,
    creditTransactionId: payingWithCreditInFull
      ? `nautilus_credit_${uuid()}`
      : undefined,
    ftlAccessorialCharges: remainingFtlAccessorialCharges,
  };

  return shipment;
};

type RawLoadStatus = {
  ftlLoadStatus?: string;
  loadStatus?: string;
};

export type RawBooking = {
  bol: string;
  loadStatus: RawLoadStatus;
  origin: string;
  destination: string;
  pickupDate: string;
  deliveryDate: string;
  totalCost: number;
  status: string;
  carrierName?: string;
  carrierId?: number;
  proNum?: string;
  txnId?: string;
  scacCode?: string;
};

export type RawLoadDetails = {
  bol: string;
  carrierName: string;
  deliveryDate: string;
  deliveryEnd: string;
  deliveryStart: string;
  destContactEmail: string;
  destContactName: string;
  destContactPhone: string;
  destCountry: string;
  destination: string;
  insurancePolicyNumber: string;
  insurancePremium: number;
  // GoShip switches between string and number for LTL and FTL loads respectively
  insuranceSumInsured: string | number;
  loadId: number;
  loadNotes: string;
  loadStatus: string;
  orderDate: string;
  origin: string;
  originCountry: string;
  pickupContactEmail: string;
  pickupContactName: string;
  pickupContactPhone: string;
  pickupDate: string;
  pickupEnd: string;
  pickupStart: string;
  status: string;
  totalPaid: number;
  totalValue: number;
  totalWeight: number;
  trackingDates: Record<string, unknown>;
  transCountry: boolean;
  txnId?: string;
  scacCode?: string;
};

type RawLTLStatus =
  | 'Dispatched'
  | 'Picked Up'
  | 'Out For Delivery'
  | 'Delivered'
  | 'Details'
  | 'Audit';

type RawFTLStatus =
  | 'SHIPMENT_PLANNING'
  | 'TENDERED'
  | 'PENDING_PICKUP'
  | 'IN_TRANSIT'
  | 'DELIVERED'
  | 'CANCELED'
  | 'UNKNOWN';

export const convertRawLoadStatus = (
  rawLoadStatus: RawLTLStatus,
): LoadStatus | undefined => {
  const loadStatusMap: { [key in RawLTLStatus | RawFTLStatus]: LoadStatus } = {
    SHIPMENT_PLANNING: 'dispatched',
    TENDERED: 'dispatched',
    PENDING_PICKUP: 'dispatched',
    IN_TRANSIT: 'in_transit',
    DELIVERED: 'delivered',
    CANCELED: 'canceled',
    UNKNOWN: 'unknown',

    Dispatched: 'dispatched',
    'Picked Up': 'picked_up',
    'Out For Delivery': 'in_transit',
    Delivered: 'delivered',
    Details: 'unknown',
    Audit: 'unknown',
  };

  return loadStatusMap[rawLoadStatus];
};

const convertRawCountry = (rawCountry: string): Country =>
  rawCountry === 'USA' ? 'USA' : 'CAN';

const convertRawLoadInsuredSum = (insuredSum: string | number) =>
  typeof insuredSum === 'undefined'
    ? 0
    : typeof insuredSum === 'string'
    ? parseFloat(insuredSum.replace(/,/g, ''))
    : insuredSum;

export const convertRawLoadDetails = (
  rawLoadDetails: RawLoadDetails,
): LoadDetails => ({
  bolId: rawLoadDetails.bol,
  load: {
    id: `${rawLoadDetails.loadId}`,
    orderDate: rawLoadDetails.orderDate,
    status: rawLoadDetails?.loadStatus
      ? convertRawLoadStatus(rawLoadDetails.loadStatus as RawLTLStatus)
      : undefined,
    notes: rawLoadDetails.loadNotes,
    totalPaid: rawLoadDetails.totalPaid,
    totalValue: rawLoadDetails.totalValue,
    totalWeight: rawLoadDetails.totalWeight,
    transCountry: rawLoadDetails.transCountry,
    insurancePolicy: rawLoadDetails.insurancePolicyNumber,
    insuredSum: convertRawLoadInsuredSum(rawLoadDetails.insuranceSumInsured),
    insurancePremium: rawLoadDetails.insurancePremium,
  },
  carrier: {
    name: rawLoadDetails.carrierName,
  },
  origin: {
    contactName: rawLoadDetails.pickupContactName,
    contactPhone: rawLoadDetails.pickupContactPhone,
    contactEmail: rawLoadDetails.pickupContactEmail,
    fullAddress: rawLoadDetails.origin,
    country: convertRawCountry(rawLoadDetails.originCountry),
    date: rawLoadDetails.pickupDate,
    startTime: new Date(rawLoadDetails.pickupStart),
    endTime: new Date(rawLoadDetails.pickupEnd),
  },
  destination: {
    contactName: rawLoadDetails.destContactName,
    contactPhone: rawLoadDetails.destContactPhone,
    contactEmail: rawLoadDetails.destContactEmail,
    fullAddress: rawLoadDetails.destination,
    country: convertRawCountry(rawLoadDetails.destCountry),
    date: rawLoadDetails.deliveryDate,
    startTime: new Date(rawLoadDetails.deliveryStart),
    endTime: new Date(rawLoadDetails.deliveryEnd),
  },
  txnId: rawLoadDetails.txnId || '',
  scacCode: rawLoadDetails.scacCode || '',
});

const convertRawAddress = (s: string): AddressSummary => {
  const m = /^(.*), ([A-Z][A-Z])$/.exec(s);
  if (m) {
    const [, city, state] = m;

    return {
      city: startCase(lowerCase(city)),
      state,
    };
  }
  return {
    city: s,
    state: '??',
  };
};

export const convertRawBooking = ({
  bol,
  loadStatus,
  origin,
  destination,
  pickupDate,
  deliveryDate,
  totalCost,
  carrierName,
  carrierId,
  proNum,
  txnId,
  scacCode,
}: RawBooking): Booking => ({
  id: bol,
  shipmentType: carrierId ? 'LTL' : 'FTL',
  loadStatus: loadStatus.loadStatus || loadStatus.ftlLoadStatus || 'unknown',
  origin: {
    date: dayjs(pickupDate).toDate(),
    address: convertRawAddress(origin),
  },
  destination: {
    date: dayjs(deliveryDate).toDate(),
    address: convertRawAddress(destination),
  },
  costBreakdown: {
    finalCost: totalCost,
  },
  carrier: carrierId
    ? ({
        id: carrierId,
        name: carrierName,
      } as CarrierSummary)
    : undefined,
  trackingNumber: proNum,
  txnId,
  scacCode: scacCode || '',
});

export const convertLegacyBooking = ({
  id,
  loadStatus,
  origin,
  destination,
  carrier,
  carrierPhoneNumber,
  shipmentType,
  trackingNumber,
  url,
  txnId,
  scacCode,
}: GetLegacyBookings_viewer_legacyBookings): Booking => ({
  id,
  shipmentType,
  loadStatus,
  origin,
  destination,
  carrier: carrier || undefined,
  trackingNumber: trackingNumber || undefined,
  txnId: txnId?.includes('txn') ? txnId : undefined,
  scacCode: scacCode || '',
  url: url || undefined,
  carrierPhoneNumber: carrierPhoneNumber || undefined,
});

export type ServerResponse<T> = {
  payload: T;
  resultsCount: number;
  version: number;
  status?: number;
  message: string;
};

const convertRawPhone = ({ areaCode, number, extension }: Phone) =>
  `(${areaCode})-${number} ${extension ? 'x' : ''}${extension || ''}`;

export const isServerResponse = <T>(p: any): p is ServerResponse<T> =>
  !!p['message'] && !!p['payload'];

export type RawUpchargeDetails = {
  bol: string;
  charge: number;
  chargeDate: string;
  costDetailId: number;
  createdBy: number;
  dateLastReminderSent: string;
  deliveryDate: string;
  emailReminderCount: number;
  itemIds: Array<number>;
  lastModifiedDate: string;
  loadId: number;
  nagCount: number;
  newCost: number;
  note: string;
  originalCost: number;
  paySelected: boolean;
  pickupDate: string;
};

export const convertRawUpchargeDetails = (
  rawUpchargeDetails: RawUpchargeDetails,
): UpchargeDetails => ({
  bolId: rawUpchargeDetails.bol,
  shipDate: rawUpchargeDetails.pickupDate,
  deliveryDate: rawUpchargeDetails.deliveryDate,
  reason: rawUpchargeDetails.note,
  originalCost: rawUpchargeDetails.originalCost,
  updatedCost: rawUpchargeDetails.newCost,
  balanceDue: rawUpchargeDetails.charge,
  // We need this to form the payload when making payment
  raw: rawUpchargeDetails,
});

type RawTerminalContact = {
  contact: string;
  contactEmail: string;
  name: string;
  phone: Phone;
};

type RawTerminalLocation = {
  address1: string;
  address2: string;
  city: string;
  countryCode: string;
  postalCode: string;
  stateCode: string;
  timeOffset: number;
  lat: number;
  lng: number;
};

export type RawTerminalInfo = {
  destTerminalContact: RawTerminalContact;
  destinationTerminal: RawTerminalLocation;
  mileageBtwOrigTermDestTerm: number;
  mileageFromDestTerminal: number;
  mileageToOrigTerminal: number;
  originTerminal: RawTerminalLocation;
  originTerminalContact: RawTerminalContact;
};

const convertRawTerminalContactAndLocation = (
  rawTerminalContact: RawTerminalContact,
  rawTerminalLocation: RawTerminalLocation,
): FullAddress => ({
  postalCode: rawTerminalLocation?.postalCode,
  city: rawTerminalLocation?.city,
  state: rawTerminalLocation?.stateCode,
  country: convertRawCountry(rawTerminalLocation?.countryCode),
  firstName: rawTerminalContact?.contact.split(' ')[0],
  lastName: rawTerminalContact?.contact.split(' ').slice(-1).join(' '),
  companyName: rawTerminalContact?.name,
  phone: convertRawPhone(rawTerminalContact?.phone),
  email: rawTerminalContact?.contactEmail,
  streetAddress: rawTerminalLocation?.address1,
  streetAddress1: rawTerminalLocation?.address2,
  timeOffset: rawTerminalLocation?.timeOffset,
  lat: rawTerminalLocation?.lat,
  lng: rawTerminalLocation?.lng,
});

export const convertRawTerminalInfo = (
  rawTerminalInfo: RawTerminalInfo,
): TerminalInfo => ({
  origin: convertRawTerminalContactAndLocation(
    rawTerminalInfo?.originTerminalContact,
    rawTerminalInfo?.originTerminal,
  ),
  destination: convertRawTerminalContactAndLocation(
    rawTerminalInfo?.destTerminalContact,
    rawTerminalInfo?.destinationTerminal,
  ),
  mileageBetween: rawTerminalInfo?.mileageBtwOrigTermDestTerm,
  mileageToOrigin: rawTerminalInfo?.mileageToOrigTerminal,
  mileageFromDest: rawTerminalInfo?.mileageFromDestTerminal,
});

export enum ErrorCode {
  BOOK_TOO_LATE,
  BOOK_UNKNOWN_FAILURE,
  INSURANCE_SERVICE_DOWN,
  NONE,
}

export type UserSubscription = {
  cartEmail: boolean;
  cartText: boolean;
  shipmentUpdateEmail: boolean;
  shipmentUpdateText: boolean;
};
